/* You can add global styles to this file, and also import other style files */
@use '../public/assets/scss/variables' as variables;

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  font-family: variables.$font-basic;
  color: variables.$primary-color;
}

@layer components {
  .form-element {
    @apply w-full px-3 py-2 border rounded border-gray-300 focus:outline-none focus:border-blue-500 transition-colors duration-500 ease-in-out;
  }
}

